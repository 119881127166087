<template>
  <div class="descriptions">
    <el-descriptions class="margin-top" :column="3" border>
      <el-descriptions-item
        label="Cost Category 费用项目"
        label-class-name="my-label"
      >
        {{ descriptions.CostCategoryName }}
      </el-descriptions-item>
      <el-descriptions-item label="Amount 费用金额" label-class-name="my-label">
        {{ descriptions.Amount }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Cost Duration 费用期间"
        label-class-name="my-label"
      >
        {{ descriptions.Duration }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Invoice NO. 发票号"
        label-class-name="my-label"
      >
        {{ descriptions.InvoiceNumber }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Bank Information 收款单位"
        label-class-name="my-label"
      >
        {{ descriptions.BankInformation }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Name of Bank Account
名称"
        label-class-name="my-label"
      >
        {{ descriptions.BankAccountName }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Name of Bank
开户行"
        label-class-name="my-label"
      >
        {{ descriptions.BankName }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Bank Account
账号"
        label-class-name="my-label"
      >
        {{ descriptions.BankAccount }}
      </el-descriptions-item>
    </el-descriptions>
    <div class="descriptions-title" v-if="descriptions.Details">明细</div>
    <el-table
      :data="descriptions.Details"
      v-if="
        descriptions.Details &&
        projectList.length !== 0 &&
        companyList.length !== 0 &&
        departmentList.length !== 0
      "
      :header-cell-style="{ color: '#909399', background: '#f5f7fa' }"
    >
      <el-table-column type="index" width="50" label="序号"> </el-table-column>
      <el-table-column prop="ProjectID" label="项目名称" width="150">
        <template slot-scope="scope">
          {{ scope.row.ProjectID | projectFormater(projectList) }}
        </template>
      </el-table-column>
      <el-table-column prop="CompanyID" label="单位名称" width="100">
        <template slot-scope="scope">
          {{ scope.row.CompanyID | companyFormater(companyList) }}
        </template>
      </el-table-column>
      <el-table-column prop="DepartmentID" label="部门名称" width="100">
        <template slot-scope="scope">
          {{ scope.row.DepartmentID | departmentFormater(departmentList) }}
        </template>
      </el-table-column>
      <el-table-column prop="CostCenterCode" label="成本中心编号" width="130">
      </el-table-column>
      <el-table-column prop="Qty" label="数量" width="80"> </el-table-column>
      <el-table-column prop="PORate" label="单价" width="140">
      </el-table-column>
      <el-table-column prop="Percentage" label="比例" width="60">
      </el-table-column>
      <el-table-column prop="Amount" label="分割金额" width="140">
      </el-table-column>
      <el-table-column prop="Remarks" label="说明" width="100">
      </el-table-column>
      <el-table-column prop="AdminDepartment" label="项目综合员" width="150">
        <template slot-scope="scope">
          <img class="signature" :src="scope.row.AdminDepartment" />
        </template>
      </el-table-column>
      <el-table-column prop="ProjectManager" label="项目被授权人" width="150">
        <template slot-scope="scope">
          <img class="signature" :src="scope.row.ProjectManager" />
        </template>
      </el-table-column>
      <el-table-column
        prop="Remarks"
        label="操作"
        width="100"
        v-if="descriptions.SubtableAction"
      >
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.checkbox"></el-checkbox>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="descriptions-title"
      v-if="descriptions.Attachments && descriptions.Attachments.length !== 0"
    >
      相关附件
    </div>
    <div
      v-for="item in descriptions.Attachments"
      @click="download(item.Url)"
      class="url"
      :key="item.ID"
    >
      {{ item.Name }}
    </div>
  </div>
</template>

<script>
import { getProjectAll, getCompanyAll, getDepartmentAll } from "@/api/user";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "-";
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : "-";
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : "-";
    },
  },
  data() {
    return {
      projectList: [],
      companyList: [],
      departmentList: [],
      VUE_APP_SERVER: null,
    };
  },
  props: {
    descriptions: {
      type: Object,
    },
  },
  methods: {
    download(data) {
      location.href = this.VUE_APP_SERVER + "upload/" + data;
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
        
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    this.VUE_APP_SERVER = process.env.VUE_APP_SERVER;
  },
};
</script>
<style lang="scss" scoped>
.descriptions-title {
  font-size: 14px;
  font-weight: bolder;
  color: #333;
  margin: 12px 0;
}
.url {
  color: #0076ff;
  cursor: pointer;
  line-height: 20px;
}
</style>
<style scoped>
.descriptions >>> .el-table__body .cell {
  color: #606266 !important;
}
.signature {
  width: 100%;
}
</style>
