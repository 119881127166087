<template>
  <div class="descriptions">
    <el-descriptions class="margin-top" :column="2" border>
      <el-descriptions-item
        label="Project Name 项目名称"
        label-class-name="my-label"
      >
        {{ descriptions.ProjectID | projectFormater(projectList) }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Sub Project Name 分项目名称"
        label-class-name="my-label"
      >
        {{ descriptions.SubProjectID | projectFormater(projectList) }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Working Unit 用工单位"
        label-class-name="my-label"
      >
        {{ descriptions.CompanyID | companyFormater(companyList) }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Working Department 部门名称"
        label-class-name="my-label"
      >
        {{ descriptions.DepartmentID | departmentFormater(departmentList) }}
      </el-descriptions-item>
    </el-descriptions>
    <div class="descriptions-title" v-if="descriptions.Details">明细</div>

    <el-table
      :data="descriptions.Details"
      v-if="descriptions.Details"
      :header-cell-style="{ color: '#909399', background: '#f5f7fa' }"
    >
      <el-table-column type="index" width="50" label="序号"> </el-table-column>
      <el-table-column prop="EmployeeID" label="员工编码" width="80">
      </el-table-column>
      <el-table-column prop="EmployeeName" label="员工姓名" width="100">
      </el-table-column>
      <el-table-column prop="JobProfession" label="工作岗位" width="100">
        <template slot-scope="scope">
          {{ scope.row.JobProfession | positionFormater(positionList) }}
        </template>
      </el-table-column>
      <el-table-column prop="OvertimeDate" label="加班日期" width="130">
      </el-table-column>
      <el-table-column prop="StartTime" label="加班起始时间" width="130">
      </el-table-column>
      <el-table-column prop="EndTime" label="加班结束时间" width="130">
      </el-table-column>
      <el-table-column
        prop="DescriptionOfOvertimeWork"
        label="加班工作内容描述"
        width="130"
      >
      </el-table-column>
      <el-table-column
        prop="Remarks"
        label="操作"
        width="100"
        v-if="descriptions.SubtableAction"
      >
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.checkbox"></el-checkbox>
        </template>
      </el-table-column>
    </el-table>

    <div
      class="descriptions-title"
      v-if="descriptions.Attachments && descriptions.Attachments.length !== 0"
    >
      相关附件
    </div>
    <div
      v-for="item in descriptions.Attachments"
      @click="download(item.Url)"
      class="url"
      :key="item.ID"
    >
      {{ item.Name }}
    </div>
  </div>
</template>

<script>
import {
  getProjectAll,
  getCompanyAll,
  allPosition,
  getDepartmentAll,
} from "@/api/user";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
  },
  data() {
    return {
      projectList: [],
      companyList: [],
      departmentList: [],
      positionList: [],
      VUE_APP_SERVER: null,
    };
  },
  props: {
    descriptions: {
      type: Object,
    },
  },
  methods: {
    download(data) {
      location.href = this.VUE_APP_SERVER + "upload/" + data;
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
        
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });

    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    this.VUE_APP_SERVER = process.env.VUE_APP_SERVER;
  },
};
</script>
<style lang="scss" scoped>
.descriptions-title {
  font-size: 14px;
  font-weight: bolder;
  color: #333;
  margin: 12px 0;
}
.url {
  color: #0076ff;
  cursor: pointer;
  line-height: 20px;
}
</style>
<style scoped>
.descriptions >>> .el-table__body .cell {
  color: #606266 !important;
}
.signature {
  width: 100%;
}
</style>
