<template>
  <div class="descriptions">
    <el-descriptions class="margin-top" :column="3" border>
      <el-descriptions-item
        label="Project Name 项目名称"
        label-class-name="my-label"
      >
        {{ descriptions.ProjectID | projectFormater(projectList) }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Department 用工部门"
        label-class-name="my-label"
      >
        {{ descriptions.DepartmentID | departmentFormater(departmentList) }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Category 岗位名称"
        label-class-name="my-label"
      >
        {{ descriptions.PositionID | positionFormater(positionList) }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Quantity 需求数量"
        label-class-name="my-label"
      >
        {{ descriptions.Qty }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Request Date  申请日期"
        label-class-name="my-label"
      >
        {{ descriptions.RequestDate }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Work Start Date
拟到岗日期"
        label-class-name="my-label"
      >
        {{ descriptions.WorkStartDate }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Working duration
拟使用周期"
        label-class-name="my-label"
      >
        {{ descriptions.DurationValue
        }}{{ descriptions.DurationType === 1 ? "月" : "年" }}
      </el-descriptions-item>
    </el-descriptions>
    <div class="descriptions-title"       v-if="descriptions.Details">明细</div>

    <el-table
      :data="descriptions.Details"
      v-if="descriptions.Details"
      :header-cell-style="{ color: '#909399', background: '#f5f7fa' }"
    >
      <el-table-column type="index" width="50" label="序号"> </el-table-column>
      <el-table-column prop="NameOfCertificate" label="证书名称" width="150">
      </el-table-column>
      <el-table-column prop="CertificateLevel" label="等级" width="100">
      </el-table-column>
      <el-table-column prop="DateOfIssue" label="取证年限" width="100">
      </el-table-column>
      <el-table-column prop="Remark" label="备注说明" width="130">
      </el-table-column>
      <el-table-column
        prop="Remarks"
        label="操作"
        width="100"
        v-if="descriptions.SubtableAction"
      >
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.checkbox"></el-checkbox>
        </template>
      </el-table-column>
    </el-table>

       <div
      class="descriptions-title"
      v-if="descriptions.Attachments && descriptions.Attachments.length !== 0"
    >
      相关附件
    </div>
    <div
      v-for="item in descriptions.Attachments"
      @click="download(item.Url)"
      class="url"
      :key="item.ID"
    >
      {{ item.Name }}
    </div>
  </div>
</template>

<script>
import { getProjectAll, allPosition, getDepartmentAll } from "@/api/user";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
  },
  data() {
    return {
      projectList: [],
      positionList: [],
      departmentList: [],
      VUE_APP_SERVER: null,
    };
  },
  props: {
    descriptions: {
      type: Object,
    },
  },
  methods: {
    download(data) {
      location.href = this.VUE_APP_SERVER + "upload/" + data;
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
        
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });

    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    this.VUE_APP_SERVER = process.env.VUE_APP_SERVER;
  },
};
</script>
<style lang="scss" scoped>
.descriptions-title {
  font-size: 14px;
  font-weight: bolder;
  color: #333;
  margin: 12px 0;
}
.url {
  color: #0076ff;
  cursor: pointer;
  line-height: 20px;
}
</style>
<style scoped>
.descriptions >>> .el-table__body .cell {
  color: #606266 !important;
}
.signature {
  width: 100%;
}
</style>
