<template>
  <div class="descriptions">
    <el-descriptions class="margin-top" :column="3" border>
      <el-descriptions-item
        label="Employee ID 员工编码"
        label-class-name="my-label"
      >
        {{ descriptions.EmployeeCode }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Employee Name 员工姓名"
        label-class-name="my-label"
      >
        {{ descriptions.EmployeeName }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Job Profession 工作岗位"
        label-class-name="my-label"
      >
        {{ descriptions.JobProfession | positionFormater(positionList) }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Project Name 项目名称"
        label-class-name="my-label"
      >
        {{ descriptions.ProjectID | departmentFormater(departmentList) }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Working Unit 用工单位"
        label-class-name="my-label"
      >
        {{ descriptions.CompanyID | companyFormater(companyList) }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Working Department 部门名称"
        label-class-name="my-label"
      >
        {{ descriptions.DepartmentID | departmentFormater(departmentList) }}
      </el-descriptions-item>
    </el-descriptions>
    <div class="descriptions-title" v-if="descriptions.Details">明细</div>

    <el-table
      :data="descriptions.Details"
      v-if="descriptions.Details"
      :header-cell-style="{ color: '#909399', background: '#f5f7fa' }"
    >
      <el-table-column type="index" width="50" label="序号"> </el-table-column>
      <el-table-column prop="TypeName" label="请假类型" width="120">
      </el-table-column>
      <el-table-column prop="StartDate" label="开始日期" width="150">
        <template slot-scope="scope">
          {{ scope.row.StartDate| tableDateFrom}}
        </template>
      </el-table-column>
  
      <el-table-column prop="EndDate" label="结束日期" width="150">
           <template slot-scope="scope">
          {{ scope.row.EndDate| tableDateFrom}}
        </template>
      </el-table-column>
      </el-table-column>
      <el-table-column prop="NumberOfLeaveDays" label="请假天数" width="100">
      </el-table-column>
      <el-table-column prop="Remark" label="备注" width="100">
      </el-table-column>
    </el-table>

    <div
      class="descriptions-title"
      v-if="descriptions.Attachments && descriptions.Attachments.length !== 0"
    >
      相关附件
    </div>
    <div
      v-for="item in descriptions.Attachments"
      @click="download(item.Url)"
      class="url"
      :key="item.ID"
    >
      {{ item.Name }}
    </div>
  </div>
</template>

<script>
import {
  getProjectAll,
  allPosition,
  getDepartmentAll,
  getCompanyAll,
} from "@/api/user";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
  },
  data() {
    return {
      projectList: [],
      positionList: [],
      departmentList: [],
      companyList: [],
      VUE_APP_SERVER: null,
    };
  },
  props: {
    descriptions: {
      type: Object,
    },
  },
  methods: {
    download(data) {
      location.href = this.VUE_APP_SERVER + "upload/" + data;
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    this.VUE_APP_SERVER = process.env.VUE_APP_SERVER;
  },
};
</script>
<style lang="scss" scoped>
.descriptions-title {
  font-size: 14px;
  font-weight: bolder;
  color: #333;
  margin: 12px 0;
}
.url {
  color: #0076ff;
  cursor: pointer;
  line-height: 20px;
}
</style>
<style scoped>
.descriptions >>> .el-table__body .cell {
  color: #606266 !important;
}
.signature {
  width: 100%;
}
</style>
