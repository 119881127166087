<template>
  <div class="descriptions">
    <el-descriptions class="margin-top" :column="3" border>
      <el-descriptions-item label="Date 制表日期" label-class-name="my-label">
        {{ descriptions.Date }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Workflow Table S/N 表单编号"
        label-class-name="my-label"
      >
        {{ descriptions.ApplicationNumber }}
      </el-descriptions-item>
      <el-descriptions-item label="Creator 申请人" label-class-name="my-label">
        {{ descriptions.Creator }}
      </el-descriptions-item>
    </el-descriptions>
    <div class="descriptions-title" v-if="descriptions.Details">明细</div>

    <el-table
      :data="descriptions.Details"
      v-if="descriptions.Details"
      :header-cell-style="{ color: '#909399', background: '#f5f7fa' }"
    >
      <el-table-column type="index" width="50" label="SN"> </el-table-column>
      <el-table-column
        prop="CategoryName"
        label="Category & Discipline"
        width="150"
      >
        <template slot-scope="scope">
          {{ scope.row.CategoryName }}
        </template>
      </el-table-column>
      <el-table-column
        prop="JobProfessionName"
        label="Job Profession"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.JobProfessionName }}
        </template>
      </el-table-column>
      <el-table-column
        prop="QulificationName"
        label="Qualification"
        width="100"
      >
      </el-table-column>
      <el-table-column prop="MinimumRate" label="Min Rate (SAR/Hr)" width="130">
      </el-table-column>
      <el-table-column prop="MaximumRate" label="Max Rate (SAR/Hr)" width="130">
      </el-table-column>
      <el-table-column prop="Remark" label="Remark" width="130">
      </el-table-column>
      <el-table-column
        label="操作"
        width="100"
        v-if="descriptions.SubtableAction"
      >
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.checkbox"></el-checkbox>
        </template>
      </el-table-column>
    </el-table>

    <div
      class="descriptions-title"
      v-if="descriptions.Attachments && descriptions.Attachments.length !== 0"
    >
      相关附件
    </div>
    <div
      v-for="item in descriptions.Attachments"
      @click="download(item.Url)"
      class="url"
      :key="item.ID"
    >
      {{ item.Name }}
    </div>
  </div>
</template>

<script>
import { getProjectAll, allPosition, getDepartmentAll } from "@/api/user";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
  },
  data() {
    return {
      projectList: [],
      positionList: [],
      departmentList: [],
      VUE_APP_SERVER: null,
    };
  },
  props: {
    descriptions: {
      type: Object,
    },
  },
  methods: {
    download(data) {
      location.href = this.VUE_APP_SERVER + "upload/" + data;
    },
  },
  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });

    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    this.VUE_APP_SERVER = process.env.VUE_APP_SERVER;
  },
};
</script>
<style lang="scss" scoped>
.descriptions-title {
  font-size: 14px;
  font-weight: bolder;
  color: #333;
  margin: 12px 0;
}
.url {
  color: #0076ff;
  cursor: pointer;
  line-height: 20px;
}
</style>
<style scoped>
.descriptions >>> .el-table__body .cell {
  color: #606266 !important;
}
.signature {
  width: 100%;
}
</style>
