import { render, staticRenderFns } from "./CostAllocation.vue?vue&type=template&id=e20cfaf4&scoped=true"
import script from "./CostAllocation.vue?vue&type=script&lang=js"
export * from "./CostAllocation.vue?vue&type=script&lang=js"
import style0 from "./CostAllocation.vue?vue&type=style&index=0&id=e20cfaf4&prod&lang=scss&scoped=true"
import style1 from "./CostAllocation.vue?vue&type=style&index=1&id=e20cfaf4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e20cfaf4",
  null
  
)

export default component.exports